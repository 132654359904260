import { Inject } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Inject({ providedIn: 'root' })
export class ThreeDService {
    private readonly openInputDialog$ = new Subject<boolean>();
    private readonly disableGenerate$ = new Subject<boolean>();
    private readonly showGenerate$ = new Subject<boolean>();
    private readonly topoData$ = new Subject<any>();
    private readonly topoOptionsDict$ = new Subject<any>();
    private readonly printView$ = new Subject<boolean>();
    private readonly redirectToSegment$ = new Subject<boolean>();
    private readonly redirectToSprinkler$ = new Subject<boolean>();
    private readonly page$ = new Subject<string>();
    private readonly openClientInfoDialog$ = new Subject<boolean>();

    openInputDialog(open: boolean): void {
        this.openInputDialog$.next(open)
    }

    openInputDialogChanges(): Observable<boolean> {
        return this.openInputDialog$.asObservable()
    }

    disableGenerate(disable: boolean): void {
        this.disableGenerate$.next(disable)
    }

    disableGenerateChanges(): Observable<boolean> {
        return this.disableGenerate$.asObservable()
    }

    showGenerate(show: boolean): void {
        this.showGenerate$.next(show)
    }

    showGenerateChanges(): Observable<boolean> {
        return this.showGenerate$.asObservable()
    }

    topoDataChanges(): Observable<any> {
        return this.topoData$.asObservable()
    }

    topoData(topoData: any): void {
        this.topoData$.next(topoData)
    }

    topoOptionsDictChanges(): Observable<any> {
        return this.topoOptionsDict$.asObservable()
    }

    topoOptionsDict(topoOptionsDict: any): void {
        this.topoOptionsDict$.next(topoOptionsDict)
    }

    printView(printView: boolean): void {
        this.printView$.next(printView)
    }

    printViewChanges(): Observable<boolean> {
        return this.printView$.asObservable()
    }

    redirectToSegment(redirectToSegment: boolean): void {
        this.redirectToSegment$.next(redirectToSegment)
    }

    redirectToSegmentChanges(): Observable<boolean> {
        return this.redirectToSegment$.asObservable()
    }

    redirectToSprinkler(redirectToSprinkler: boolean): void {
        this.redirectToSprinkler$.next(redirectToSprinkler)
    }

    redirectToSprinklerChanges(): Observable<boolean> {
        return this.redirectToSprinkler$.asObservable()
    }

    page(page: string): void {
        this.page$.next(page)
    }

    pageChanges(): Observable<string> {
        return this.page$.asObservable()
    }

    openClientInfoDialog(open: boolean): void {
        this.openClientInfoDialog$.next(open)
    }

    openClientInfoDialogChanges(): Observable<boolean> {
        return this.openClientInfoDialog$.asObservable()
    }
}