<app-header></app-header>
<div class="user-alert" *ngIf="isNonSuperadmin">
    <p>Access Denied. Only super admins have access for this site.</p>
</div>
<mat-sidenav-container *ngIf="!isNonSuperadmin" class="side-navigations" [ngClass]="{'side-navigations-igp': page=='igp'}">
    <mat-sidenav opened class="sidenav-list-container" mode="side">
        <mat-nav-list>
            <div matTooltip="Client Info">
                <a [routerLink]="['/client']"  routerLinkActive="active"><img class="pinsheet"
                    (click)="onPageChange('client')"    src="../../../assets/images/Sidenav/client.svg" alt="Client info " /></a>
            </div>
            <div matTooltip="IGP">
                <a [routerLink]="['/igp']" routerLinkActive="active"><img class="pinsheet"
                    (click)="onPageChange('igp')" src="../../../assets/images/Sidenav/IGP.svg" alt="IGP " /></a>
            </div>
            <div matTooltip="Super Admin" *ngIf="isSuperAdminManager">
                <a [routerLink]="['/super-admin']"  routerLinkActive="active"><img class="pinsheet"
                    (click)="onPageChange('super-admin')"    src="../../../assets/images/Sidenav/SA.svg" alt="Super Admin" /></a>
            </div>
            <div matTooltip="External Access">
                <a [routerLink]="['/external-access']"  routerLinkActive="active"><img class="pinsheet"
                    (click)="onPageChange('external-access')"    src="../../../assets/images/Sidenav/externalAccess.svg" alt="External Access" /></a>
            </div>
            <div matTooltip="Training Library">
                <a [routerLink]="['/training-library']"  routerLinkActive="active"><img class="pinsheet"
                    (click)="onPageChange('training-library')"    src="../../../assets/images/Sidenav/trainingLibrary.svg" alt="Training Library" /></a>
            </div>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content id="main-content">
        <router-outlet></router-outlet>
        <!-- <app-client-info></app-client-info> -->
        
    </mat-sidenav-content>
</mat-sidenav-container>
<!--<app-footer></app-footer>-->