import { createReducer, on } from "@ngrx/store";
import { clientsDetails,showInactiveAction,userProfileAction,addUpdateClientAction,newUserAction } from "../actions/clients.actions";

export const initialState: Record<string, string>[] = []
export const showInactiveInitialState: boolean = false
export const addUpdateClientinitialState: boolean = false
export const newUserInitialState: boolean = false
export const userProfileInitialState: any = []

export const clientsReducer = createReducer(
    initialState,
    on(clientsDetails, (state, { clientsData }) => clientsData)
)
export const showInactiveReducer = createReducer(
    showInactiveInitialState,
    on(showInactiveAction, (state, { showInactive }) => showInactive)
)
export const userProfileReducer = createReducer(
    userProfileInitialState,
    on(userProfileAction, (state, { userProfile }) => userProfile)
)
export const addUpdateClientReducer = createReducer(
    addUpdateClientinitialState,
    on(addUpdateClientAction, (state, { addUpdateClient }) => addUpdateClient)
)
export const newUserReducer = createReducer(
    newUserInitialState,
    on(newUserAction, (state, { newUser }) => newUser)
)